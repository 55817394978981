import React from "react";
import { Row, Col, Navbar, Container } from "react-bootstrap";
import "./Footer.css";

function Footer() {
  return (
    <Navbar
      expand="lg"
      fixed="bottom"
      className="nav-bottom p-0 footer"
      dir="ltr"
    >
      <Container>
        <Navbar.Brand className="navbar-icons-container">
          <Row>
            <Col align="center">
              <i
                className="fa fa-facebook navbar-icons-container"
                aria-hidden="true"
              ></i>
              <i
                className="fa fa-instagram navbar-icons-container"
                aria-hidden="true"
              ></i>
              <i
                className="fa fa-twitter navbar-icons-container"
                aria-hidden="true"
              ></i>
            </Col>
          </Row>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Footer;
