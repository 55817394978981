import { React, useState, useEffect } from "react";
import "./Search.css";
import {
  Container,
  InputGroup,
  FormControl,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function Search({ activateSearch, status, reset }) {
  const [number, setNumber] = useState({ valid: false, value: "" });
  const [checkStat, setCheckStat] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const checkingStatus = [
      {
        txt: ` ...בודק משלוחים`,
        img: <i className="em em-truck stat-icon"></i>,
      },
      {
        txt: ` ...בודק חבילות `,
        img: <i className="em em-package stat-icon"></i>,
      },
      {
        txt: ` ...בודק חיובים `,
        img: <i className="em em-moneybag stat-icon"></i>,
      },
      {
        txt: `...הבדיקה אורכת מספר רגעים, נא להמתין`,
        img: <i className="em em-clock330 stat-icon"></i>,
      },
      {
        txt: ` ...בודק חבילות שלא נאספו `,
        img: <i className="em em-package stat-icon"></i>,
      },
      {
        txt: ` ...בודק מול ספקים `,
        img: <i className="em em-briefcase stat-icon"></i>,
      },
      {
        txt: `...הבדיקה אורכת מספר רגעים, נא להמתין`,
        img: <i className="em em-clock330 stat-icon"></i>,
      },
      {
        txt: ` ...בודק מול מפיצים `,
        img: <i className="em em-motor_scooter stat-icon"></i>,
      },
      {
        txt: ` ...בודק חבילות שהוחזרו לשולח`,
        img: <i className="em em-no_entry stat-icon"></i>,
      },
      {
        txt: `...הבדיקה אורכת מספר רגעים, נא להמתין`,
        img: <i className="em em-clock330 stat-icon"></i>,
      },
    ];
    if (status % 7 === 0) {
      setCheckStat(
        checkingStatus[Math.floor(Math.random() * checkingStatus.length)]
      );
    }
  }, [status]);

  useEffect(() => {
    if (!number.valid) {
      reset(0);
    }
  }, [number, reset]);

  useEffect(() => {
    if (status === 100) {
      handleShow();
    }
  }, [status]);

  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    const format = /^[0][5][0|2|3|4|5|7|8]{1}[-]{0,1}[0-9]{7}$/;
    if (format.test(onlyDigits)) {
      setNumber({ valid: true, value: onlyDigits });
    } else {
      setNumber({ valid: false, value: onlyDigits });
    }
  };

  const notFound = `מספר ${number.value}: הבדיקה הושלמה - לא נמצאו משלוחים ו/או חיובים ממתינים `;

  const yourDate = new Date();

  return (
    <Container className="search-container">
      <Row>
        <Col>
          <span className="check-status-text" dir="rtl">
            היכן החבילה שלי
            <i className="em em-grey_question"></i>
          </span>
        </Col>
      </Row>
      <Row className="form-row">
        <Col>
          <InputGroup size="lg">
            <InputGroup.Text id="inputGroup-sizing-lg" className="input-text">
              <Row>
                <Col align="center">
                  {(status > 0 && status < 100 && (
                    <div
                      style={{ width: 50, height: 45 }}
                      className="progress-bar-round"
                    >
                      <CircularProgressbar
                        value={status}
                        text={`${status}%`}
                        background
                        backgroundPadding={6}
                        styles={buildStyles({
                          backgroundColor: "#208b3a",
                          textColor: "#fff",
                          pathColor: "#fff",
                          trailColor: "transparent",
                        })}
                      />
                    </div>
                  )) ||
                    (number.value.length === 0 && (
                      <i className="em em-mag_right"></i>
                    )) ||
                    (number.value.length > 0 && !number.valid && (
                      <i className="em em-warning"></i>
                    )) ||
                    (number.valid && (
                      <i className="em em-white_check_mark"></i>
                    ))}
                </Col>
              </Row>
            </InputGroup.Text>
            <FormControl
              className="form-control"
              placeholder="05********"
              type="tel"
              maxLength="10"
              value={number.value}
              onChange={(e) => checkInput(e)}
              disabled={status > 0 && status < 100 ? true : false}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="direction-status-text" dir="rtl">
            הזינו את מספר הטלפון בו התקבלה הודעת המשלוח
            <i
              className="em em-incoming_envelope"
              style={{ marginRight: "0.3rem" }}
            ></i>
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="check-button"
            disabled={
              (number.valid ? false : true) ||
              (status > 0 && status < 100 ? true : false)
            }
            onClick={() => activateSearch(1)}
          >
            <Row dir="rtl">
              <Col>חיפוש</Col>
            </Row>
          </Button>
        </Col>
      </Row>
      <Row className={status === 100 ? "status-raw-complete" : "status-raw"}>
        {(status === 100 && number.valid && <Col>{notFound}</Col>) ||
          (status > 0 && status < 100 && (
            <Col>
              {checkStat.txt}
              {checkStat.img}
            </Col>
          ))}
      </Row>
      <Row>
        <Col>
          <Modal show={show} onHide={handleClose} centered animation={false}>
            <Modal.Header closeButton className="modael-header">
              <Container>
                <Row>
                  <Col align="center">
                    <Modal.Title className="modal-title">
                      <i className="em em-warning warning-icon"></i>
                      <span className="announce-txt">
                        {" "}
                        הודעה חשובה ללקוחותינו{" "}
                      </span>
                      <i className="em em-warning warning-icon"></i>
                    </Modal.Title>
                  </Col>
                </Row>
              </Container>
            </Modal.Header>
            <Row>
              <Col align="center">
                <Modal.Body className="modal-body">
                  <Row>
                    <Col align="center">
                      <p>{`זוהי הודעה מעודכנת לתאריך ${
                        yourDate.toISOString().split("T")[0]
                      }`}</p>
                      <p>
                        לקוחות יקרים, אנו חווים שיבושים בזמני אספקה ומשלוח וכן
                        תקלות טכניות שונות בגינן נשלחו הודעות אודות משלוחים
                        ממתינים ו/או חיובים שונים
                      </p>
                      <p>
                        במידה ובבדיקה באתר לא נמצאו משלוחים תחת מספר הטלפון אליו
                        נשלחה ההודעה מדובר בתקלה ולא בוצע כל חיוב בפועל
                      </p>
                      <p>
                        אנו עושים את מירב המאמצים לספק לקהל לקוחותינו שירות
                        מיטבי, עמכם הסליחה{" "}
                      </p>
                    </Col>
                  </Row>
                </Modal.Body>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default Search;
