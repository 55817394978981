import { React } from "react";
import { Row, Col, Spinner, ListGroup } from "react-bootstrap";

import "./Package.css";

function Package({ status }) {
  const data = [
    {
      name: "נקלט במרכז לוגיסטי",
      assist: <i className="em em-airplane"></i>,
      status: false,
      icon: [
        <i className="em em-question"></i>,
        <i className="em em-x"></i>,
        <i className="em em-white_check_mark"></i>,
        <Spinner animation="border" variant="danger" />,
      ],
    },

    {
      name: "השלים ביקורת מכס",
      assist: <i className="em em-customs"></i>,
      status: false,
      icon: [
        <i className="em em-question"></i>,
        <i className="em em-x"></i>,
        <i className="em em-white_check_mark"></i>,
        <Spinner animation="border" variant="danger" />,
      ],
    },
    {
      name: "הועבר למרכז הפצה מחוזי",
      assist: <i className="em em-articulated_lorry"></i>,
      status: false,
      icon: [
        <i className="em em-question"></i>,
        <i className="em em-x"></i>,
        <i className="em em-white_check_mark"></i>,
        <Spinner animation="border" variant="danger" />,
      ],
    },
    {
      name: "יצא להפצה עם שליח",
      assist: <i className="em em-motor_scooter"></i>,
      status: false,
      icon: [
        <i className="em em-question"></i>,
        <i className="em em-x"></i>,
        <i className="em em-white_check_mark"></i>,
        <Spinner animation="border" variant="danger" />,
      ],
    },
    {
      name: "נמסר ללקוח",
      assist: <i className="em em-house_with_garden"></i>,
      status: false,
      icon: [
        <i className="em em-question"></i>,
        <i className="em em-x"></i>,
        <i className="em em-white_check_mark"></i>,
        <Spinner animation="border" variant="danger" />,
      ],
    },
    {
      name: "הוחזר לשולח",
      assist: <i className="em em-no_entry"></i>,
      status: false,
      icon: [
        <i className="em em-question"></i>,
        <i className="em em-x"></i>,
        <i className="em em-white_check_mark"></i>,
        <Spinner animation="border" variant="danger" />,
      ],
    },
  ];

  return (
    <div
      className={
        status > 0 ? "package-container-checking" : "package-container"
      }
    >
      <ListGroup className="status-list" dir="rtl">
        {data.map((item, key) => (
          <ListGroup.Item
            className={status > 0 ? "list-item-checking" : "list-item"}
            key={key}
          >
            <Row dir="rtl" className="item-row">
              <Col align="center" xs={2}>
                {item.assist}
              </Col>
              <Col align="center" xs={8}>
                {item.name}
              </Col>
              <Col align="center" xs={2}>
                {(status > 0 && status < 100 && item.icon[3]) ||
                  (status === 0 && item.icon[0]) ||
                  (status === 100 && item.icon[1])}
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default Package;
