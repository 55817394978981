import { React, useState } from "react";
import "./Header.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function Header({ loading, checked }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      expand="lg"
      dir="rtl"
      fixed="top"
      className="navbar-top"
      variant="dark"
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Brand>
          חבילה בקליק
          <i className="em em-calling" style={{ marginRight: "0.3rem" }}></i>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="nav-burger"
          onClick={() => setExpanded(expanded ? false : "expanded")}
          disabled={
            (loading > 0 && loading < 100 ? true : false) || (!checked && true)
          }
        />
        <Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse">
          <Nav className="me-auto">
            <Nav.Link
              className="menu-item"
              dir="rtl"
              as={Link}
              to={"/"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => setExpanded(expanded ? false : "expanded")}
            >
              <span style={{ marginLeft: "0.4rem" }}>מעקב חבילות</span>
              <i className="em em-package"></i>
            </Nav.Link>
            <Nav.Link
              className="menu-item"
              dir="rtl"
              as={Link}
              to={"/about"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => setExpanded(expanded ? false : "expanded")}
            >
              <span style={{ marginLeft: "0.4rem" }}>אודותינו</span>
              <i className="em em-truck"></i>
            </Nav.Link>

            <Nav.Link
              className="menu-item"
              dir="rtl"
              as={Link}
              to={"/contact"}
              style={{ color: "inherit", textDecoration: "inherit" }}
              onClick={() => setExpanded(expanded ? false : "expanded")}
            >
              <span style={{ marginLeft: "0.4rem" }}>צור קשר</span>
              <i className="em em-email"></i>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
