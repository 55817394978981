import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./Contact.css";

function Contact() {
  return (
    <Container fluid className="contact-container">
      <Row>
        <Col>
          <h1>
            <i className="em em-phone"></i>
            <span> צור קשר</span>
          </h1>
        </Col>
      </Row>
      <Row className="contact-row">
        <Col align="center">
          <div className="contact-txt">
            <div className="imessage">
              <p className="from-them" dir="rtl">
                היי חבילה בקליק, אני בעל עסק קטן - איך אפשר לעשות אתכם עסקים?{" "}
              </p>
              <p className="from-me">
                היי, תודה על ההתעניינות! בשלב זה אנו עובדים בנפחי הפצה בינוניים
                עד גדולים בלבד
              </p>
              <p className="from-them" dir="rtl">
                אני מעוניין להגיש בקשה לשיתוף פעולה, כיצד אוכל לעשות זאת?
              </p>
              <p className="from-me">
                מצויין 😎 אתה מוזמן לפנות אלינו באמצעות כתובת המייל כאן למטה
              </p>
              <p className="from-them emoji">👍</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mail-row">
        <Col>
          <span style={{ marginRight: "0.2rem" }}>
            contact@ship-click.co.il
          </span>
          {"   "}
          <a href="mailto:contact@ship-click.co.il">
            {"  "}
            <i className="em em-email"></i>
          </a>{" "}
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
