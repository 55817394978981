import { React, useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./Hero.css";

function Hero() {
  const [count, setCount] = useState({ shipments: 1, routes: 1, distros: 1 });

  useEffect(() => {
    function nextFibo(n) {
      let a = (n * (1 + Math.sqrt(5))) / 2.0;
      return Math.round(a);
    }
    let newCount = count;
    if (
      newCount.shipments < 100000 ||
      newCount.routes < 2500 ||
      newCount.distros < 100
    ) {
      setTimeout(function () {
        setCount({
          shipments:
            newCount.shipments < 100000
              ? nextFibo(newCount.shipments)
              : newCount.shipments,
          routes:
            newCount.routes < 2500
              ? nextFibo(newCount.routes)
              : newCount.routes,
          distros:
            newCount.distros < 100
              ? nextFibo(newCount.distros)
              : newCount.distros,
        });
      }, 250);
    }
  }, [count]);

  return (
    <Container dir="rtl" className="hero-container">
      <Row>
        <Row className="data-row">
          <Col align="center">
            <span className="num-label">
              {count.shipments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          </Col>{" "}
        </Row>
        <Row>
          <Col align="center">
            <span className="txt-label">משלוחים ביום</span>
          </Col>{" "}
        </Row>
      </Row>
      <Row>
        <Row className="data-row">
          <Col align="center">
            <span className="num-label">
              {count.routes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          </Col>{" "}
        </Row>
        <Row>
          <Col align="center">
            <span className="txt-label">קוי חלוקה ביום</span>
          </Col>{" "}
        </Row>
      </Row>
      <Row>
        <Row className="data-row">
          <Col align="center">
            <span className="num-label">{count.distros}</span>
          </Col>{" "}
        </Row>
        <Row>
          <Col align="center">
            <span className="txt-label">מרכזי מסירה ברחבי הארץ</span>
          </Col>{" "}
        </Row>
      </Row>
    </Container>
  );
}

export default Hero;
