import { React, useState, useEffect } from "react";
import "./App.css";
import Header from "./Header";
import Search from "./Search";
import clip from "./DAL/clip.mp4";
import Footer from "./Footer";
import Package from "./Package";
import Contact from "./Contact";
import About from "./About";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  const [pending, setPending] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (pending < 100 && pending > 0) {
      setTimeout(function () {
        let newPending = pending + 1;
        setPending(newPending);
      }, 350);
    }
  }, [pending]);

  useEffect(() => {
    if (pending === 100) {
      setChecked(true);
    }
  }, [pending]);

  return (
    <Router>
      <div className="App">
        <Header loading={pending} checked={checked} />
        <video autoPlay loop muted className="background-video">
          <source src={clip} type="video/mp4" />
          <source src={clip} type="video/ogg" />
        </video>
        <Switch>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route path="/:something">
            <Search
              activateSearch={setPending}
              status={pending}
              reset={setPending}
            />

            <Package status={pending} />
          </Route>
          <Route exact path="/">
            <Search
              activateSearch={setPending}
              status={pending}
              reset={setPending}
            />

            <Package status={pending} />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
