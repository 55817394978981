import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./About.css";
import Hero from "./Hero.js";

function About() {
  return (
    <Container fluid className="about-container">
      <Row>
        <Col>
          <h1>
            <i className="em em-truck"></i>
            <span> אודות חבילה בקליק</span>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="speech-div" dir="rtl">
            <p style={{ padding: "none" }}>
              קבוצת חבילה בקליק מספקת פתרונות לוגיסטיקה, שילוח והפצה מדלת-לדלת
              ברחבי הארץ. לזכות החברה נזקפות למעלה מ-10 שנות פעילות ענפה בתחום
              פעילותה{" "}
              <i
                className="fas fa-map-marked-alt"
                style={{ marginRight: "0.4rem" }}
              ></i>
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="speech-div" dir="rtl">
            <p style={{ padding: "none" }}>
              בזכות תשתיות לוגיסטיות וטכנולוגיות מצויינות, לצד נסיון עתיר שנים,
              אנו מציעים שירותי שילוח מהיום להיום ובתוך 24 שעות לכל בית בישראל,
              משלב האיסוף ועד לשלב המסירה ללקוח{" "}
              <i
                className="fas fa-people-carry"
                style={{ marginRight: "0.4rem" }}
              ></i>
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Hero />
      </Row>
    </Container>
  );
}

export default About;
